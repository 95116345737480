
import { defineComponent } from "@vue/runtime-core"
import TestSystemContainer from "@/components/TestSystemContainer.vue"

export default defineComponent({
  name: "Imprint",
  components: {
    TestSystemContainer,
  },
})
