
import { defineComponent } from "@vue/runtime-core"
import ModalTestSystem from "@/components/modal/ModalTestSystem.vue"
export default defineComponent({
  name: "TestSystemContainer",
  components: { ModalTestSystem },
  methods: {
    // add modalShown Value to sessionStorage on click Button from modal
    setModalSession() {
      sessionStorage.setItem("modalShown", "true")
    },
  },
})
