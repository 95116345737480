
import { defineComponent, onMounted, ref, useSlots } from "vue"
import { Modal } from "bootstrap"
export default defineComponent({
  name: "ModalTestSystem",
  props: {
    name: {
      type: String,
      default: (): string => "",
      describe: "it will set a name and id on modal",
    },
  },
  setup() {
    const slots = useSlots()

    const myModal = ref<HTMLElement | null>(null)
    let modal: Modal

    onMounted(() => {
      if (myModal.value) {
        modal = new Modal(myModal.value)

        // add modalShown Value to sessionStorage on modal close
        var modalToggle = document.getElementById("test-system-info") // relatedTarget
        modalToggle?.addEventListener("hidden.bs.modal", function () {
          sessionStorage.setItem("modalShown", "true")
        })

        // check sessionStorage if modal already shown or not, if not yet shown then show modal
        if (sessionStorage.getItem("modalShown") === "true") {
          return false
        } else {
          modal.show()
        }
      }
    })

    return { myModal, slots }
  },
})
