import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  key: 0,
  class: "modal-header align-items-center justify-content-center p-2"
}
const _hoisted_5 = {
  key: 1,
  class: "modal-body p-0"
}
const _hoisted_6 = {
  key: 2,
  class: "modal-footer p-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    ref: "myModal",
    role: "dialog",
    id: _ctx.name,
    "aria-labelledby": _ctx.name + '-label',
    "aria-hidden": "true",
    "data-bs-backdrop": "static"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots.header)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.body)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "body")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 8, _hoisted_1))
}